define("nullify-website/templates/components/matrix-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABXK75BX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"full-width h-100\"],[14,1,\"wrapper\"],[12],[2,\"\\n\\n\\t\"],[10,\"canvas\"],[15,0,[31,[\"h-100 \",[30,[36,0],[\"matrix\"],[[\"from\"],[\"nullify-website/styles/components/matrix-container\"]]]]]],[14,1,\"matrix\"],[12],[13],[2,\"\\n\\n\\t\"],[10,\"svg\"],[15,0,[31,[[30,[36,0],[\"hide\"],[[\"from\"],[\"nullify-website/styles/components/matrix-container\"]]]]]],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n\\t\\t\"],[10,\"defs\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"filter\"],[14,1,\"blur-horizontal\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"feGaussianBlur\"],[14,\"stdDeviation\",\"3,0\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"filter\"],[14,1,\"blur-vertical\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"feGaussianBlur\"],[14,\"stdDeviation\",\"0,6\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"forward\"],[[\"from\"],[\"nullify-website/styles/components/matrix-container\"]]]]]],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/matrix-container.hbs"
    }
  });

  _exports.default = _default;
});
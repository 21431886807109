define("nullify-website/styles/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "bottom": "_bottom_8o5tjb"
  };
  _exports.default = _default;
});
define("nullify-website/templates/components/resource-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GD1+oD1A",
    "block": "{\"symbols\":[\"tag\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"card grid-item fade-in \",[30,[36,0],[\"card-hover\"],[[\"from\"],[\"nullify-website/styles/components/resource-card\"]]]]]],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\\t\\t\"],[10,\"h5\"],[14,0,\"display-5 card-title text-center\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,0,\"badge badge-pill badge-dark p-2 m-1\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"local-class\",\"resource\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/resource-card.hbs"
    }
  });

  _exports.default = _default;
});
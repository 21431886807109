define("nullify-website/styles/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "split": "_split_1nikmi",
    "officers": "_officers_1nikmi",
    "strike": "_strike_1nikmi"
  };
  _exports.default = _default;
});
define("nullify-website/templates/contribute/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V31VVCA2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card p-5 my-5 text-center align-items-center\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[14,0,\"display-5 text-center\"],[12],[2,\"Which would you like to contribute to?\"],[13],[2,\"\\n\\t\"],[6,[37,0],null,[[\"type\",\"class\",\"route\"],[\"button\",\"btn btn-primary m-4 p-3\",\"contribute.resource-name\"]],[[\"default\"],[{\"statements\":[[10,\"h5\"],[14,0,\"display-5 mb-0\"],[12],[2,\"Resources\"],[13]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[6,[37,0],null,[[\"type\",\"class\",\"disabled\",\"route\"],[\"button\",\"btn btn-primary p-3\",\"true\",\"contribute.resource-name\"]],[[\"default\"],[{\"statements\":[[10,\"h5\"],[14,0,\"display-5 mb-0\"],[12],[2,\"Blog Post\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,1]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/contribute/index.hbs"
    }
  });

  _exports.default = _default;
});
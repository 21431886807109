define("nullify-website/styles/components/matrix-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hide": "_hide_11swkb",
    "matrix": "_matrix_11swkb",
    "blur": "_blur_11swkb",
    "forward": "_forward_11swkb"
  };
  _exports.default = _default;
});
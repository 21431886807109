define("nullify-website/services/user", ["exports", "firebase/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    store: Ember.inject.service(),
    admin: false,

    async login() {
      const auth = await this.get('firebaseApp').auth();
      const provider = new _app.default.auth.GoogleAuthProvider();
      return auth.signInWithPopup(provider).then(user => {
        this.store.findRecord('officer', user.user.uid).then(() => {
          this.set('admin', true);
        }).catch(() => {
          // Not admin
          console.log("Successfully Signed In");
        });
      });
    },

    logout() {
      this.set('admin', false);
      return this.get('session').invalidate();
    },

    // TODO: This might be causing the logo to not show up
    init() {
      this._super();

      if (this.get('session').isAuthenticated) {
        this.store.findRecord('officer', this.get('session').data.authenticated.user.uid).then(() => {
          this.set('admin', true);
        }).catch(() => {});
      }
    }

  });

  _exports.default = _default;
});
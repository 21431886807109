define("nullify-website/templates/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UMXY5j2C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"footer\"],[15,0,[31,[\"footer font-small bg-dark \",[30,[36,0],[\"footer\"],[[\"from\"],[\"nullify-website/styles/components/footer\"]]]]]],[12],[2,\" \"],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[12],[2,\"© 2020 \"],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"NULLify Computer Security Group\"]],\"parameters\":[]}]]],[13],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"font\"],[14,\"size\",\"1\"],[12],[2,\"This web site and its content is not endorsed by or maintained by the University of Nebraska\\n\\t\\t\\t\\tSystem or\\n\\t\\t\\t\\tany of its affiliate institutions or subsidiaries. The names “University of Nebraska” and as well as\\n\\t\\t\\t\\trelated names,\\n\\t\\t\\t\\tmarks, emblems and images are registered trademarks of their associated entities.\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"local-class\",\"link-to\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/footer.hbs"
    }
  });

  _exports.default = _default;
});
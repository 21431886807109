define("nullify-website/templates/contribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOnHLKHM",
    "block": "{\"symbols\":[\"pg\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12 pt-4 fade-in\"],[12],[2,\"\\n\\n\\t\\t\\t\"],[10,\"h1\"],[14,0,\"display-4 mt-3 text-center\"],[12],[2,\"Contribute\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[8,\"bs-progress\",[[24,0,\"my-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[8,[32,1,[\"bar\"]],[],[[\"@value\",\"@minValue\",\"@maxValue\",\"@showLabel\",\"@type\"],[[32,0,[\"model\",\"progress\"]],0,5,false,\"primary\"]],null],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\\t\\t\\t\"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,0],[[32,0],\"digress\"],null]],[12],[2,\"Digress\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"onclick\",[30,[36,0],[[32,0],\"progress\"],null]],[12],[2,\"Progress\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[1,[34,1]],[2,\"\\n\\n\"],[2,\"\\t\\t\\t\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/contribute.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/components/-private-api/detect-render", ["exports", "ember-google-maps/components/-private-api/detect-render"], function (_exports, _detectRender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _detectRender.default;
    }
  });
});
define("nullify-website/components/admin/suggestion-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      accept(suggestion) {
        var id = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < 20; i++) {
          id += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        this.get('store').createRecord('resource', {
          id: id,
          name: suggestion.get('name'),
          url: suggestion.get('url'),
          tags: suggestion.get('tags'),
          uses: 0
        }).save();
        suggestion.deleteRecord();
        suggestion.save();
      },

      reject(suggestion) {
        suggestion.deleteRecord();
        suggestion.save();
      },

      dragEnd2({
        sourceList,
        sourceIndex,
        sourceArgs,
        targetList,
        targetIndex,
        targetArgs
      }) {
        let tag = sourceList.objectAt(sourceIndex);
        let suggestion = this.get('suggestion');
        suggestion.get('tags').removeObject(tag);
        suggestion.save(); // tag.save();

        return;
      },

      determineForeignPosition({
        /*draggedItem, */
        items
      }) {
        return items.length;
      }

    }
  }).reopenClass({
    positionalParams: ['suggestion']
  });

  _exports.default = _default;
});
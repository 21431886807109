define("nullify-website/models/calendar", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    url: attr('string'),
    key: attr('string'),
    sharableURL: attr('string')
  });

  _exports.default = _default;
});
define("nullify-website/templates/components/highlight-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QbS7eZo+",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"ember-highlight-line-numbers\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[30,[36,0],[[32,1]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"pre\"],[12],[2,\"  \"],[2,[34,6]],[2,\"\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unbound\",\"lineNumbers\",\"-track-array\",\"each\",\"hasLineNumbers\",\"if\",\"highlight\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/highlight-js.hbs"
    }
  });

  _exports.default = _default;
});
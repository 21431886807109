define("nullify-website/controllers/contribute/resource-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      async progress() {
        // console.log('1');
        this.get('progress')();
      },

      digress() {
        // console.log('1');
        this.get('progress')();
      }

    }
  });

  _exports.default = _default;
});
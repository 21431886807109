define("nullify-website/controllers/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    search: '',
    filterTags: Ember.A([]),
    filtered: Ember.computed('search', 'model.resources', 'filterTags.[]', function () {
      const resources = this.get('model.resources');
      const search = this.get('search').toLowerCase();
      let tags = this.get('filterTags'); // console.log(tags)
      // TODO: Filter by tag

      if (Ember.isEmpty(search)) {
        return resources.sortBy('uses');
      }

      return resources.filter(function (resources) {
        return resources.get('name').toLowerCase().match(search) || resources.get('url').toLowerCase().match(search);
      });
    }),
    actions: {
      addTag(tag) {
        this.get('filterTags').addObject(tag); // this.set('filterTags', ["TEST"])
      },

      removeTag(tag) {
        this.get('filterTags').removeObject(tag);
      },

      async usedResource(resource) {
        resource.set('uses', resource.get('uses') + 1);
        resource.save();
      }

    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/components/officer-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "officer-card": "_officer-card_3wsrwz",
    "image-holder": "_image-holder_3wsrwz",
    "text-holder": "_text-holder_3wsrwz",
    "profile-picture": "_profile-picture_3wsrwz",
    "top-row": "_top-row_3wsrwz"
  };
  _exports.default = _default;
});
define("nullify-website/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    user: Ember.inject.service(),

    init() {
      this._super(...arguments);

      window.addEventListener('beforeinstallprompt', e => {
        // Stash the event so it can be triggered later.
        let deferredPrompt = e;
        setTimeout(function () {
          deferredPrompt.prompt();
        }, 10000); // deferredPrompt.userChoice.then((choiceResult) => {
        // 	if (choiceResult.outcome === 'accepted') {
        // 		console.log('User accepted the A2HS prompt');
        // 	} else {
        // 		console.log('User dismissed the A2HS prompt');
        // 	}
        // 	deferredPrompt = null;
        // });
      });
    },

    isResourcesRoute: Ember.computed.equal('router.currentRouteName', 'resources')
  });

  _exports.default = _default;
});
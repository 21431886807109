define("nullify-website/styles/components/resource-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "thumbnail": "_thumbnail_bfuhor",
    "thumbnail-container": "_thumbnail-container_bfuhor",
    "card-hover": "_card-hover_bfuhor"
  };
  _exports.default = _default;
});
define("nullify-website/templates/contribute/resource-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3VIWGvM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card p-5 my-5 text-center align-items-center\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[14,0,\"display-5 text-center\"],[12],[2,\"What type of content does this resource have?\"],[13],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"text-muted text-center\"],[12],[2,\"Drag any of the tags below onto the resource you've created to reflect the type of content this resource has.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"p-2\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"tagName\",\"type\",\"class\",\"route\",\"model\"],[\"button\",\"button\",\"btn btn-primary\",\"contribute.resource-url\",[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"←\\n\\t\\tBack\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"ml-auto p-2\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"tagName\",\"type\",\"class\",\"route\"],[\"button\",\"button\",\"btn btn-primary float-right\",\"contribute.resource-finish\"]],[[\"default\"],[{\"statements\":[[2,\"Next→\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,1]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/contribute/resource-tags.hbs"
    }
  });

  _exports.default = _default;
});
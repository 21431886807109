define("nullify-website/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zh3kmGTE",
    "block": "{\"symbols\":[\"announcement\",\"index\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"full-width h-100 \",[30,[36,2],[\"gradient-background\"],[[\"from\"],[\"nullify-website/styles/index\"]]]]]],[14,1,\"background-image\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container h-100\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"row justify-content-center h-100\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-10 align-self-center\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,5]]],\"hasEval\":false,\"upvars\":[\"terminal\",\"unless\",\"local-class\",\"-track-array\",\"each\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/index.hbs"
    }
  });

  _exports.default = _default;
});
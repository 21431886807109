define("nullify-website/styles/hsctf-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "leftside": "_leftside_1vo4t9",
    "rightside": "_rightside_1vo4t9",
    "pokemon-right": "_pokemon-right_1vo4t9",
    "outline": "_outline_1vo4t9",
    "display-t": "_display-t_1vo4t9",
    "circle": "_circle_1vo4t9",
    "countdown": "_countdown_1vo4t9",
    "canvas-container": "_canvas-container_1vo4t9",
    "logo": "_logo_1vo4t9",
    "live": "_live_1vo4t9"
  };
  _exports.default = _default;
});
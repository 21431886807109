define("nullify-website/controllers/admin/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tags: Ember.A([]),
    isAddingTag: false,

    init() {
      this._super(...arguments); // this.set('tags', this.store.findAll('tag').copy())


      this.store.findAll('tag').then(res => {
        // tags = { ...tags } // shallow clone
        let tags = res.toArray();
        this.set('tags', tags);
      });
    },

    actions: {
      add() {
        var id = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < 20; i++) {
          id += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        this.store.createRecord('resource', {
          id: id,
          name: "Title",
          url: "https://example.com",
          uses: 0
        }).save();
      },

      newTag() {
        var id = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < 20; i++) {
          id += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        this.store.createRecord('tag', {
          id: id,
          name: "New Tag"
        }).save();
        this.toggleProperty('isAddingTag');
      },

      dragEnd({
        sourceList,
        sourceIndex,
        sourceArgs,
        targetList,
        targetIndex,
        targetArgs
      }) {
        const unsortableList = this.get('tags');

        if (targetList !== unsortableList) {
          let tag = sourceList.objectAt(sourceIndex);
          let resource = targetArgs.resource;
          resource.get('tags').pushObject(tag);
          resource.save();
          tag.save();
        }

        return;
      },

      determineForeignPosition({
        /*draggedItem, */
        items
      }) {
        return items.length;
      }

    }
  });

  _exports.default = _default;
});
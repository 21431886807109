define("nullify-website/templates/contribute/resource-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JIJMBxIK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card p-5 my-5 text-center align-items-center\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[14,0,\"display-5 text-center\"],[12],[2,\"What is the name of the resource?\"],[13],[2,\"\\n\\t\"],[1,[30,[36,3],null,[[\"type\",\"name\",\"id\",\"autocomplete\",\"value\",\"required\",\"class\"],[\"text\",\"name\",\"name\",\"off\",[35,2,[\"name\"]],\"true\",[30,[36,1],[\"text-white text-center \",[30,[36,0],[\"question\"],[[\"from\"],[\"nullify-website/styles/contribute/resource-name\"]]]],null]]]]],[2,\"\\n\\t\"],[10,\"label\"],[14,\"for\",\"name\"],[12],[10,\"span\"],[14,0,\"text-white\"],[12],[2,\"What's the name of this resource?\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],null,[[\"tagName\",\"type\",\"class\",\"onclick\",\"route\",\"model\"],[\"button\",\"button\",\"btn btn-primary float-right\",[30,[36,4],[[32,0],\"progress\"],null],\"contribute.resource-url\",[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"Next →\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[34,6]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"concat\",\"model\",\"input\",\"action\",\"link-to\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/contribute/resource-name.hbs"
    }
  });

  _exports.default = _default;
});
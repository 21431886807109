define("nullify-website/styles/hsctf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "courier": "_courier_6k46m9",
    "stencil": "_stencil_6k46m9",
    "background": "_background_6k46m9",
    "paper": "_paper_6k46m9",
    "hr": "_hr_6k46m9",
    "black": "_black_6k46m9",
    "confidential": "_confidential_6k46m9",
    "input-group-header": "_input-group-header_6k46m9",
    "input-group-field": "_input-group-field_6k46m9",
    "submit-button": "_submit-button_6k46m9",
    "submit-button-green": "_submit-button-green_6k46m9"
  };
  _exports.default = _default;
});
define("nullify-website/components/admin/resource-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    actions: {
      edit(resource) {
        resource.rollbackAttributes();
        this.toggleProperty('isEditing');
      },

      save(resource) {
        resource.save();
        this.toggleProperty('isEditing');
      },

      remove(resource) {
        resource.deleteRecord();
        resource.save();
      },

      dragEnd2({
        sourceList,
        sourceIndex,
        sourceArgs,
        targetList,
        targetIndex,
        targetArgs
      }) {
        let tag = sourceList.objectAt(sourceIndex);
        let resource = this.get('resource');
        resource.get('tags').removeObject(tag);
        resource.save(); // tag.save();

        return;
      },

      determineForeignPosition({
        /*draggedItem, */
        items
      }) {
        return items.length;
      }

    }
  }).reopenClass({
    positionalParams: ['resource']
  });

  _exports.default = _default;
});
define("nullify-website/components/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    actions: {
      async login() {
        await this.get('user').login();
      },

      logout() {
        this.get('user').logout();
        this.get('router').transitionTo('index');
      }

    }
  });

  _exports.default = _default;
});
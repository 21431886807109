define("nullify-website/controllers/admin/announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editing: false,
    btnText: "Save",
    actions: {
      toggle() {
        this.toggleProperty('editing');
      },

      save(announcement) {
        let controller = this;
        controller.set('btnText', "Saving...");
        announcement.save();
        controller.set('btnText', "Saved!");
        setTimeout(function () {
          controller.set('btnText', "Save");
          controller.toggleProperty('editing');
        }, 1000);
      }

    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/admin/announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "elem": "_elem_45w7ii",
    "elem-close": "_elem-close_45w7ii",
    "elem-minimize": "_elem-minimize_45w7ii",
    "elem-fullscreen": "_elem-fullscreen_45w7ii",
    "terminal-section": "_terminal-section_45w7ii",
    "terminal-outer": "_terminal-outer_45w7ii",
    "terminal-container": "_terminal-container_45w7ii",
    "terminal-header": "_terminal-header_45w7ii",
    "terminal-controls": "_terminal-controls_45w7ii",
    "terminal-body": "_terminal-body_45w7ii",
    "terminal-body-inner": "_terminal-body-inner_45w7ii",
    "terminal-user": "_terminal-user_45w7ii",
    "blink": "_blink_45w7ii",
    "toggle-button": "_toggle-button_45w7ii",
    "append": "_append_45w7ii",
    "code-edit": "_code-edit_45w7ii",
    "save": "_save_45w7ii",
    "display-none": "_display-none_45w7ii"
  };
  _exports.default = _default;
});
define("nullify-website/styles/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "navbar": "_navbar_1qmr18",
    "content": "_content_1qmr18"
  };
  _exports.default = _default;
});
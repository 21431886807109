define("nullify-website/templates/contribute/resource-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f88tRNb9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card p-5 my-5 text-center align-items-center\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[14,0,\"display-5 text-center\"],[12],[2,\"What is the url of the resource?\"],[13],[2,\"\\n\\t\"],[1,[30,[36,3],null,[[\"type\",\"name\",\"id\",\"autocomplete\",\"value\",\"required\",\"class\"],[\"url\",\"url\",\"url\",\"off\",[35,2,[\"url\"]],\"true\",[30,[36,1],[\"text-white text-center \",[30,[36,0],[\"question\"],[[\"from\"],[\"nullify-website/styles/contribute/resource-url\"]]]],null]]]]],[2,\"\\n\\t\"],[10,\"label\"],[14,\"for\",\"url\"],[12],[10,\"span\"],[14,0,\"text-white\"],[12],[2,\"What's the name of this resource?\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"p-2\"],[12],[2,\"\\n\\t\\t\"],[6,[37,5],null,[[\"tagName\",\"type\",\"class\",\"onclick\",\"route\"],[\"button\",\"button\",\"btn btn-primary\",[30,[36,4],[[32,0],\"progress\"],null],\"contribute.resource-name\"]],[[\"default\"],[{\"statements\":[[2,\"← Back\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"ml-auto p-2\"],[12],[2,\"\\n\\t\\t\"],[6,[37,5],null,[[\"tagName\",\"type\",\"class\",\"onclick\",\"res\",\"route\",\"model\"],[\"button\",\"button\",\"btn btn-primary float-right\",[30,[36,4],[[32,0],\"progress\"],null],[35,2],\"contribute.resource-tags\",[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"Next →\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[34,6]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"concat\",\"res\",\"input\",\"action\",\"link-to\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/contribute/resource-url.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/admin/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "code": "_code_df6qm1",
    "button": "_button_df6qm1"
  };
  _exports.default = _default;
});
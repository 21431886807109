define("nullify-website/models/officer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    position: attr('string'),
    about: attr('string'),
    email: attr('string'),
    photo: attr('string'),
    order: attr('number')
  });

  _exports.default = _default;
});
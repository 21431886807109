define("nullify-website/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pMH2AEO",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,1]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"content\"],[[\"from\"],[\"nullify-website/styles/application\"]]]]]],[14,1,\"content\"],[12],[2,\"\\n\\t\"],[1,[30,[36,3],null,[[\"class\"],[\"h-100\"]]]],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"footer\",\"navbar\",\"local-class\",\"liquid-outlet\",\"isResourcesRoute\",\"unless\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/application.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/matrix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hide": "_hide_v20mz4",
    "matrix": "_matrix_v20mz4",
    "blur": "_blur_v20mz4"
  };
  _exports.default = _default;
});
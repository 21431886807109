define("nullify-website/templates/components/admin/suggestion-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "azxwISzK",
    "block": "{\"symbols\":[\"tag\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col text-truncate\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"mb-0 align-middle text-white d-none d-lg-block\"],[12],[1,[35,0,[\"name\"]]],[2,\" — \"],[10,\"span\"],[14,0,\"text-muted font-italic\"],[12],[1,[35,0,[\"url\"]]],[13],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"mb-0 align-middle text-white d-lg-none\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col align-middle\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"button\"],[14,\"role\",\"button\"],[14,0,\"btn btn-sm btn-danger float-right ml-2\"],[15,\"onclick\",[30,[36,1],[[32,0],\"reject\",[35,0]],null]],[12],[1,[30,[36,2],[\"times\"],null]],[13],[2,\"\\n\\t\\t\"],[10,\"button\"],[14,\"role\",\"button\"],[14,0,\"btn btn-sm btn-success float-right ml-2\"],[15,\"onclick\",[30,[36,1],[[32,0],\"accept\",[35,0]],null]],[12],[1,[30,[36,2],[\"plus\"],null]],[13],[2,\"\\n\\n\"],[6,[37,4],null,[[\"items\",\"isHorizontal\",\"dragEndAction\",\"additionalArgs\",\"isRtl\",\"style\"],[[35,0,[\"tags\"]],true,[30,[36,1],[[32,0],\"dragEnd2\"],null],[30,[36,3],null,[[\"suggestion\"],[[35,0]]]],true,\"border: 1px solid red;\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"span\"],[14,0,\"badge badge-pill badge-dark p-2 mx-1 float-right my-auto\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"suggestion\",\"action\",\"fa-icon\",\"hash\",\"drag-sort-list\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/admin/suggestion-row.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "logo-text": "_logo-text_r9masr",
    "contact-card": "_contact-card_r9masr",
    "mail-list": "_mail-list_r9masr",
    "map-wrapper": "_map-wrapper_r9masr"
  };
  _exports.default = _default;
});
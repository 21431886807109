define("nullify-website/models/meeting", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    date: (0, _model.attr)('date'),
    startTime: (0, _model.attr)('date'),
    endTime: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    topic: (0, _model.attr)('string'),
    zoomConf: (0, _model.attr)('number'),
    attendees: (0, _model.attr)('string')
  });

  _exports.default = _default;
});
define("nullify-website/styles/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "fadein": "_fadein_1dg45n",
    "slideInFromLeft": "_slideInFromLeft_1dg45n",
    "slideInFromRight": "_slideInFromRight_1dg45n",
    "slideInFromTop": "_slideInFromTop_1dg45n"
  };
  _exports.default = _default;
});
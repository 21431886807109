define("nullify-website/router", ["exports", "nullify-website/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('about');
    this.route('contact');
    this.route('sponsors');
    this.route('resources');
    this.route('hsctf-old');
    this.route('hsctf');
    this.route('calendar');
    this.route('meeting', {
      path: '/meeting/:meeting_id'
    });
    this.route('admin', function () {
      this.route('announcement');
      this.route('calendar');
      this.route('officers');
      this.route('resources');
      this.route('hsctf');
    });
    this.route('404', {
      path: '/*path'
    });
    this.route('contribute', function () {
      this.route('resource-name');
      this.route('resource-url');
      this.route('resource-tags');
      this.route('resource-finish');
    });
  });
  var _default = Router;
  _exports.default = _default;
});
define("nullify-website/routes/resources", ["exports", "emberfire/mixins/realtime-route", "emberfire/mixins/performance-route"], function (_exports, _realtimeRoute, _performanceRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, _performanceRoute.default, {
    model() {
      return this.store.findAll('resource', {
        include: 'tags'
      }).then(res => {
        // console.log(res)
        // console.log(res.get('firstObject'))
        return Ember.RSVP.hash({
          resources: res,
          tags: this.store.findAll('tag')
        });
      });
    }

  });

  _exports.default = _default;
});
define("nullify-website/controllers/admin/officers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedSort: ['order'],
    sortedOfficers: Ember.computed.sort('model', 'selectedSort'),
    actions: {
      add() {
        let last = this.get('sortedOfficers.lastObject');
        var id = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < 20; i++) {
          id += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        this.store.createRecord('officer', {
          id: id,
          name: '',
          position: '',
          about: '',
          email: 'name@unomaha.edu',
          photo: '',
          order: last.get('order') + 1
        });
      }

    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "footer": "_footer_uta2kd"
  };
  _exports.default = _default;
});
define("nullify-website/styles/contribute/resource-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "question": "_question_nmpc8f",
    "appear": "_appear_nmpc8f"
  };
  _exports.default = _default;
});
define("nullify-website/templates/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rjH8NSy6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container mt-3 mt-lg-5\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[34,0]],[2,\"\\n\\t\\t\\t\"],[10,\"h1\"],[14,0,\"display-4 text-center mb-4\"],[12],[2,\"Home\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[14,0,\"text-muted text-center\"],[12],[2,\"I would like to put some cool graphs about visitor stats from Google Analytics here but I'm too lazy.\"],[10,\"br\"],[12],[13],[2,\" Also idk where to put the logout button so it's here:\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"hr\"],[12],[13],[2,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[1,[30,[36,2],null,[[\"type\",\"value\"],[\"text\",[35,1]]]]],[2,\"\\n\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,3],[[32,0],\"sendMessage\"],null],[12],[2,\"Send Discord Message\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"hr\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-outline-danger\"],[4,[38,3],[[32,0],\"logout\"],null],[12],[2,\"Logout\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,4]]],\"hasEval\":false,\"upvars\":[\"admin/admin-nav\",\"message\",\"input\",\"action\",\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/admin/index.hbs"
    }
  });

  _exports.default = _default;
});
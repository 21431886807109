define("nullify-website/components/admin/officer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    actions: {
      edit(officer) {
        officer.rollbackAttributes();
        this.toggleProperty('isEditing');
      },

      save(officer) {
        officer.save();
        this.toggleProperty('isEditing');
      },

      remove(officer) {
        officer.deleteRecord();
        officer.save();
      }

    }
  }).reopenClass({
    positionalParams: ['officer']
  });

  _exports.default = _default;
});
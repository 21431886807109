define("nullify-website/routes/admin/hsctf", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "emberfire/mixins/realtime-route", "emberfire/mixins/performance-route"], function (_exports, _authenticatedRouteMixin, _realtimeRoute, _performanceRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, _performanceRoute.default, _authenticatedRouteMixin.default, {
    user: Ember.inject.service(),

    beforeModel() {
      if (!this.get("user.admin")) {
        this.transitionTo('404', '404');
      }
    },

    model() {
      return this.store.findAll('hsctf-submission');
    }

  });

  _exports.default = _default;
});
define("nullify-website/styles/components/navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "skip-main": "_skip-main_kegw28",
    "user-icon": "_user-icon_kegw28",
    "wrap": "_wrap_kegw28",
    "overlay": "_overlay_kegw28",
    "text": "_text_kegw28"
  };
  _exports.default = _default;
});
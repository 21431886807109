define("nullify-website/templates/components/admin/admin-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hEIK8g5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-pills nav-fill mb-3\"],[12],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.index\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.announcement\"]],[[\"default\"],[{\"statements\":[[2,\"Announcement\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.calendar\"]],[[\"default\"],[{\"statements\":[[2,\"Calendar\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.officers\"]],[[\"default\"],[{\"statements\":[[2,\"Officers\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.resources\"]],[[\"default\"],[{\"statements\":[[2,\"Resources\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"admin.hsctf\"]],[[\"default\"],[{\"statements\":[[2,\"HSCTF\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/components/admin/admin-nav.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B3VjjO1w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container h-100\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row h-100\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12 text-center align-self-center\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h1\"],[14,0,\"display-1\"],[12],[2,\"404\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"h5\"],[14,0,\"display-5 text-muted\"],[12],[2,\"The page you have requested cannot be found.\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"liquid-outlet\"]}",
    "meta": {
      "moduleName": "nullify-website/templates/404.hbs"
    }
  });

  _exports.default = _default;
});
define("nullify-website/styles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gradient-background": "_gradient-background_1mfahq",
    "gradient": "_gradient_1mfahq",
    "banner": "_banner_1mfahq"
  };
  _exports.default = _default;
});
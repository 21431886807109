define("nullify-website/controllers/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.inject.service(),
    router: Ember.inject.service(),
    message: "",
    actions: {
      logout() {
        this.get('user').logout();
        this.get('router').transitionTo('index');
      },

      sendMessage() {
        let controller = this;
        var request = new XMLHttpRequest();
        request.open("POST", "https://discordapp.com/api/webhooks/719595772429205574/FwztIEo9KK_iJwwNP2_gbkcHcleNBqBkexMLPOMKviGyZQUF1-1nO0Y21lkSBo0UwMkF");
        request.setRequestHeader('Content-type', 'application/json');
        var params = {
          username: "Nullify Announcements",
          content: controller.get('message')
        };
        request.send(JSON.stringify(params));
      }

    }
  });

  _exports.default = _default;
});
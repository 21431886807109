define("nullify-website/controllers/admin/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    date: null,
    startTime: null,
    endTime: null,
    location: '',
    topic: '',
    description: '',
    zoomConf: 98490437492,
    collapsed: true,
    // OLD
    btnText: "Save",
    urlDisabled: true,
    apiDisabled: true,
    shareDisabled: true,
    actions: {
      updateDate(date) {
        this.set('date', date);
      },

      updateStartTime(time) {
        this.set('startTime', time);
        document.getElementsByClassName("time-picker__button")[0].textContent = time.format("hh:mm a");
      },

      updateEndTime(time) {
        this.set('endTime', time);
        document.getElementsByClassName("time-picker__button")[1].textContent = time.format("hh:mm a");
      },

      toggleCollapse() {
        console.log("toggled");
        this.toggleProperty('collapsed');
      },

      add() {
        let controller = this;
        let newEvent = this.store.createRecord('meeting', {
          date: this.date.toDate(),
          startTime: this.startTime.toDate(),
          endTime: this.endTime.toDate(),
          description: this.description,
          location: this.location,
          topic: this.topic,
          zoomConf: this.zoomConf,
          attendees: ''
        });
        let startDate = new Date();
        startDate.setFullYear(this.date.year());
        startDate.setMonth(this.date.month());
        startDate.setDate(this.date.date());
        startDate.setHours(this.startTime.hour());
        startDate.setMinutes(this.startTime.minutes());
        startDate.setSeconds(0);
        console.log(startDate.toUTCString());
        let endDate = new Date();
        endDate.setFullYear(this.date.year());
        endDate.setMonth(this.date.month());
        endDate.setDate(this.date.date());
        endDate.setHours(this.endTime.hour());
        endDate.setMinutes(this.endTime.minutes());
        endDate.setSeconds(0);
        console.log(endDate.toUTCString()); // let event = {
        // 	'summary': this.topic,
        // 	'location': this.location,
        // 	'description': this.description,
        // 	'start': {
        // 		'dateTime': ,
        // 		'timeZone': 'America/Chicago',
        // 	},
        // 	'end': {
        // 		'dateTime': ,
        // 		'timeZone': 'America/Chicago',
        // 	},
        // }
        // TODO: Add to Google Calendar
        // TODO: Send Discord reminder

        newEvent.save();
        $("#success-alert").fadeTo(5000, 500).slideDown(500, function () {
          $("#success-alert").slideUp(500);
        });
        controller.set('date', null);
        controller.set('startTime', null);
        controller.set('endTime', null);
        controller.set('topic', '');
        controller.set('description', '');
        controller.set('location', '');
        controller.set('zoomConf', 6540412498);
      },

      hideSuccess() {
        $("#success-alert").hide();
      },

      // OLD
      toggleURL() {
        this.toggleProperty('urlDisabled');
      },

      toggleAPI() {
        this.toggleProperty('apiDisabled');
      },

      toggleShare() {
        this.toggleProperty('shareDisabled');
      },

      save(calendar) {
        let controller = this;
        controller.set('btnText', "Saving...");
        calendar.save();
        controller.set('btnText', "Saved!");
        setTimeout(function () {
          controller.set('btnText', "Save");
        }, 1000);
      }

    }
  });

  _exports.default = _default;
});
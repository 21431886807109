define("nullify-website/routes/admin/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    user: Ember.inject.service(),

    beforeModel() {
      if (!this.get("user.admin")) {
        this.transitionTo('404', '404');
      }
    }

  });

  _exports.default = _default;
});
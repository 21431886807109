define("nullify-website/styles/components/terminal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "elem": "_elem_p2q9ks",
    "elem-close": "_elem-close_p2q9ks",
    "elem-minimize": "_elem-minimize_p2q9ks",
    "elem-fullscreen": "_elem-fullscreen_p2q9ks",
    "terminal-section": "_terminal-section_p2q9ks",
    "terminal-outer": "_terminal-outer_p2q9ks",
    "terminal-container": "_terminal-container_p2q9ks",
    "terminal-header": "_terminal-header_p2q9ks",
    "terminal-controls": "_terminal-controls_p2q9ks",
    "terminal-body": "_terminal-body_p2q9ks",
    "terminal-body-inner": "_terminal-body-inner_p2q9ks",
    "terminal-user": "_terminal-user_p2q9ks",
    "blink": "_blink_p2q9ks",
    "skip-button": "_skip-button_p2q9ks",
    "append": "_append_p2q9ks"
  };
  _exports.default = _default;
});
define("nullify-website/styles/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "code": "_code_11c6ib",
    "button": "_button_11c6ib",
    "info-button": "_info-button_11c6ib",
    "alt-text": "_alt-text_11c6ib"
  };
  _exports.default = _default;
});
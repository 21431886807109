define("nullify-website/styles/components/fake-terminal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "elem": "_elem_1yhzh4",
    "elem-close": "_elem-close_1yhzh4",
    "elem-minimize": "_elem-minimize_1yhzh4",
    "elem-fullscreen": "_elem-fullscreen_1yhzh4",
    "terminal-section": "_terminal-section_1yhzh4",
    "terminal-outer": "_terminal-outer_1yhzh4",
    "terminal-container": "_terminal-container_1yhzh4",
    "terminal-header": "_terminal-header_1yhzh4",
    "terminal-controls": "_terminal-controls_1yhzh4",
    "terminal-body": "_terminal-body_1yhzh4",
    "terminal-body-inner": "_terminal-body-inner_1yhzh4",
    "terminal-user": "_terminal-user_1yhzh4",
    "blink": "_blink_1yhzh4",
    "skip-button": "_skip-button_1yhzh4",
    "append": "_append_1yhzh4"
  };
  _exports.default = _default;
});
define("nullify-website/routes/admin/announcement", ["exports", "emberfire/mixins/realtime-route", "emberfire/mixins/performance-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _realtimeRoute, _performanceRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, _performanceRoute.default, _authenticatedRouteMixin.default, {
    user: Ember.inject.service(),

    beforeModel() {
      if (!this.get("user.admin")) {
        this.transitionTo('404', '404');
      }
    },

    model() {
      return this.store.findAll('announcement', {
        orderBy: 'created'
      });
    }

  });

  _exports.default = _default;
});
define("nullify-website/session-stores/application", ["exports", "emberfire/session-stores/firebase"], function (_exports, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _firebase.default.extend();

  _exports.default = _default;
});